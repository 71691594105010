@opacity-5: rgba(0, 0, 0, 0.5);

@opacity-85: rgba(0, 0, 0, 0.85);

:root {
    --red-color: #e21313;
    --gray-color: #f0f2f5;
    --dark-color: #e4e4e4;
    --white-color: #ffffff;
    --green-color: #015801;
    --orange-color: #d04702;
    --secondary-color: #fafafa;
}