.over-div {
    overflow: hidden;
}
.cursor-pointer {
    cursor: pointer;
}
.text-center { 
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}
.f-14 {
    font-size: 14px !important;
}
.f-28 {
    font-size: 28px !important;
}
.vh100 {
    .over-div();
    height: 100vh !important;
}
.p-0 {
    padding: 0 !important;
}
.p-10 {
    padding: 10px !important;
}
.p-15 {
    padding: 15px !important;
}
.p-l-10 {
    padding-left: 10px !important;
}
.p-r-10 {
    padding-right: 10px !important;
}
.p-2-7 {
    padding: 2px 7px;
}
.p-r-l-8 {
    padding: 0 8px;
}
.hg-100 {
    height: 100%;
}
.h-auto {
    height: auto !important;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-8 {
    margin-top: 8px !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-16 {
    margin-top: 16px !important;
}
.m-t-55 {
    margin-top: 55px;
}
.m-b-5 {
    margin-bottom: 5px !important;
}
.m-l-5 {
    margin-left: 5px !important;
}
.m-r-0 {
    margin-right: 0 !important;
}
.ln-normal {
    line-height: normal !important;
}
.text-uppercase {
    text-transform: uppercase;
}
button {
    .cursor-pointer();
    span {
        .cursor-pointer();
    }
}
.txt-overflow {
    .over-div();
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ant-form-item {
    .m-b-5();
    .anticon {
        .m-l-5();
    }
    .ant-input-search-button {
        .anticon {
            margin-left: 0 !important;
        }
    }
}
.fixed-left {
    top: 0;
    left: 0;
    position: fixed;
    width: 54.16666667%;
    @media screen and (max-width: 990px) {
        display: none !important;
    }
}
.fixed-right {
    top: 0;
    right: 0;
    .hg-100();
    position: fixed;
    width: 45.83333333%;
    background-color: var(--white-color) !important;    
    @media screen and (max-width: 990px) {
        width: 100%;
    }
    .ant-col-18 {
        @media screen and (max-width: 690px) {
            flex: 0 0 87.5%;
            max-width: 87.5%;
        }
    }
    .ant-input-prefix {
        margin-right: 8px;
    }
    .ant-input-affix-wrapper {
        padding: 5.6px 8px 5.6px 5px;
    }
}
.site-layout {
    //.p-r-l-8();
    background-color: var(--white-color);
    border-left: 1px solid @border-color-base;
    > .ant-layout-content {
        background-color: var(--white-color);
    }
}
.col-empresas {
    .ant-card-body {
        .p-0();
    }
    .color-primary {
        color: @primary-color;
    }
    .col-logo-emp {
        .p-r-10();
        text-align: center;
        img {
            width: 120px;
        }
        @media screen and (max-width: 1142px) {
            img {
                width: 100%;
            }
        }
        @media screen and (max-width: 992px) {
            img {
                width: 120px;
            }
        }
    }
}
body {
    .ant-card-bordered {
        border: 1px solid @border-color-base;
    }
    .ant-input {
        &.ant-input-sm {
            .p-2-7();
            height: 24px;
        }
    }
    .ant-form-small  {
        height: inherit;
        .ant-form-item-label {
            .p-0();
            > label {
                height: inherit;
                line-height: 11px;
            }
        }
    }
    .ant-input[disabled],
        .ant-input-number-disabled {
            color: rgba(0, 0, 0, 1) !important; 
    }
    .ant-btn > .anticon + span, .tabela.ant-btn > span + .anticon {
        .m-l-5();
    }
    .ant-modal-confirm-body {
        flex: 1;
        display: flex;
        .text-center();
        flex-direction: column;
        justify-content: center;
        > .anticon {
            .m-r-0();
            .m-b-5();
            float: inherit;
            font-size: 25px;
        }
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
        margin-left: 0;
    }
    .ant-modal-confirm {
        .ant-modal-confirm-btns {
            .m-t-10();
            display: flex;
            float: inherit;
            justify-items: center;
            justify-content: center;
        }
    }
    .ant-modal-mask, 
        .ant-drawer-mask {
        background-color: rgba(0, 0, 0, 0.70);
    }
    .ant-modal-content {
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .ant-modal-close {
            color: var(--white-color);
            .ant-modal-close-x {
                .h-auto();
                width: auto;
                .ln-normal();
                padding: 10px 15px !important;
            }
        }
        .ant-modal-header {
            color: var(--white-color);
            padding: 8px 15px !important;
            background-color: @primary-color !important;
            .ant-modal-title {
                .text-uppercase();
                color: var(--white-color);                
            }
        }
        .ant-modal-body {
            .p-15();
        }
    }
    .ant-layout.ant-layout-has-sider>.ant-layout, 
        .ant-layout.ant-layout-has-sider>.ant-layout-content {
        overflow-x: hidden;
    }
}
.form-data {
    .ant-input {
        &.ant-input-sm {
            height: 24px;
            line-height: 24px;
            padding: 2px 0 2px 22px;
            &.ant-input-disabled {
                padding: 2px 0 2px 7px;
            }
        }
    }
}
input[type="date"]::-webkit-calendar-picker-indicator {    
    left: 0;
    margin: 0;
    cursor: pointer;
    position: absolute;
    padding: 1px 15px 0 5px;
}
@media screen and (max-width: 992px) {
    .ant-btn-sm {
        height: 32px !important;
        padding: 5.6px 15px !important;
    }
    .ant-input-sm {
        height: 32px !important;
    }  
    .ant-form-small {
        .ant-form-item-control-input {
            min-height: 32px !important;
        }
    }
    .ant-input-search {        
        .ant-input-group-addon {
            .ant-btn-sm {
                padding: 0 !important;
            }
        }
    }
    .ant-input-affix-wrapper-sm {
        padding: 0 7px !important;
    }
}