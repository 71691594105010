:root {
  --red-color: #e21313;
  --gray-color: #f0f2f5;
  --dark-color: #e4e4e4;
  --white-color: #ffffff;
  --green-color: #015801;
  --orange-color: #d04702;
  --secondary-color: #fafafa;
}
.col-banners {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.col-banners:before {
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  content: '';
  height: 100vh;
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
}
.col-banners .ant-carousel {
  height: 100vh;
}
.col-banners .ant-carousel .slick-slider {
  height: 100vh;
}
.col-banners .ant-carousel .slick-slide {
  overflow: hidden;
  text-align: center;
  height: 100vh !important;
}
